<template>
    <BaseContainer>
        <v-content slot="principal" class="pr-2 pl-2" style="background-color: #e0e0e0;">
            <v-toolbar class="ma-2" style="border-radius: 30px;">
                <v-btn icon link to="/">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-spacer/>
                <v-toolbar-title>QR Code</v-toolbar-title>
                <v-spacer/>
            </v-toolbar>
            <v-card elevation="2" class="ma-1">
                <v-card-text>
                    <v-expansion-panels v-model="panel" focusable>
                        <v-expansion-panel>
                            <v-expansion-panel-header><b>QR Code Único</b></v-expansion-panel-header>
                            <v-expansion-panel-content class="mt-4">
                                <div style="display: flex; flex-direction: column; align-items: center;">
                                    <img v-if="qrcode" :src="qrcode" style="height: 150px;"/>
                                    <v-btn v-if="qrcode" @click="downloadImage(qrcode, 'qrcode_zapermenu')" color="primary" small>
                                        <v-icon small>mdi-download</v-icon>
                                        Baixar
                                    </v-btn>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                            <v-expansion-panel-header><b>QR Code para Mesas</b></v-expansion-panel-header>
                            <v-expansion-panel-content class="mt-4" style="display: flex; flex-direction: column;">
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            label="Nº Mesa Inicial"
                                            v-model="start"
                                            placeholder="0"
                                            v-mask="['###']"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Nº Mesa Final"
                                            v-model="end"
                                            placeholder="0"
                                            v-mask="['###']"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>

                                <v-btn
                                    @click="gerarQRCodes"
                                    color="primary"
                                    class="mt-2"
                                >
                                    <v-icon>mdi-qrcode</v-icon>
                                    Gerar QrCodes
                                </v-btn>

                                <div v-if="qrcodes.length > 1" id="qrcodes" :style="`height: ${$vuetify.breakpoint.height - 480}px; overflow-x: auto;`">
                                    <div v-for="(qrCode, index) in qrcodes" :key="index" class="qrcode-container">
                                        <img :src="qrCode.src" style="height: 150px;"/>
                                        <p style="font-weight: bold;">Mesa {{ index + 1 }}</p>
                                        <v-btn @click="downloadQRCode(qrCode.src, index)" color="primary" small>
                                            <v-icon small>mdi-download</v-icon>
                                            Baixar
                                        </v-btn>
                                    </div>
                                </div>

                                <v-btn
                                    v-if="qrcodes.length > 1"
                                    @click="downloadAllQRCodes"
                                    color="success"
                                >
                                    <v-icon>mdi-download</v-icon>
                                    Baixar todos os QR Codes (ZIP)
                                </v-btn>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
            </v-card>
        </v-content>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import { installJsZip } from '@/plugins/jszip';
import { mapState } from 'vuex';

export default {
    name: 'QrCode',

    components: {
        BaseContainer,
    },

    data: () => ({
        panel: 0,
        loading: null,
        start: 1,
        end: 10,
        qrcodes: [],
        qrcode: null,
    }),

    mounted() {
        this.makeQrCode();
    },

    computed: {
        ...mapState([
            'usuario',
        ]),

        baseUrl() {
            return `https://app.zapermenu.com.br/${this.usuario.dominio}`;
        },
    },

    methods: {
        makeQrCode() {
            this.qrcode = `https://image-charts.com/chart?chs=300x300&cht=qr&chl=${encodeURIComponent(this.baseUrl)}`;
        },

        async downloadImage(imageSrc, nameOfDownload = 'my-image.png') {
            const response = await fetch(imageSrc);
            const blobImage = await response.blob();
            const href = URL.createObjectURL(blobImage);

            const anchorElement = document.createElement('a');
            anchorElement.href = href;
            anchorElement.download = nameOfDownload;

            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        },

        async downloadAllQRCodes() {
            if (this.end < this.start) {
                alert('Valores inválidos');
                return;
            }

            if (this.end - this.start > 100) {
                alert('Intervalo não pode ser maior que 100');
                return;
            }

            await installJsZip();

            const zip = window.$jszip;

            for (let i = this.start; i <= this.end; i++) {
                const mesa = i;
                const qrCodeImgSrc = `https://image-charts.com/chart?chs=300x300&cht=qr&chl=${encodeURIComponent(this.baseUrl + `?mesa=${mesa}`)}`;

                const response = await fetch(qrCodeImgSrc);
                const blob = await response.blob();
                zip.file(`qr_code_mesa_${mesa}.png`, blob);
            }

            zip.generateAsync({ type: 'blob' }).then((content) => {
                const zipFilename = 'qrcodes.zip';
                this.downloadImage(URL.createObjectURL(content), zipFilename);
            });
        },

        gerarQRCodes() {
            if (this.end <= this.start) {
                alert('Valores inválidos');
                return;
            }

            if (this.end - this.start > 100) {
                alert('Intervalo não pode ser maior que 100');
                return;
            }

            const qrcodes = [];

            for (let i = this.start; i <= this.end; i++) {
                const mesa = i;
                const qrCodeImgSrc = `https://image-charts.com/chart?chs=300x300&cht=qr&chl=${encodeURIComponent(this.baseUrl + `?mesa=${mesa}`)}`;

                qrcodes.push({ src: qrCodeImgSrc });
            }

            this.qrcodes = qrcodes;
        },

        downloadQRCode(imageSrc, index) {
            this.downloadImage(imageSrc, `qr_code_mesa_${index + 1}.png`);
        },
    },
}
</script>

<style scoped>
#qrcodes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 5px;
    border-radius: 5px;
    background: #e9e9e9;
}

.qrcode-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}
</style>
