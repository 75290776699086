
export function installJsZip() {
    const url = 'https://cdnjs.cloudflare.com/ajax/libs/jszip/3.10.1/jszip.min.js';

    return new Promise((resolve, reject) => {
        if (document.getElementById('jszip-src')) {
            resolve();
        } else {
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.id = 'jszip-src';
            s.src = url;

            s.addEventListener('load', () => {
                // eslint-disable-next-line
                window.$jszip = new JSZip();
                setTimeout(() => resolve(), 100);
            });

            s.addEventListener('error', (error) => {
                console.log(error);
                reject(new Error('Erro ao carregar o JSZip: ' + JSON.stringify(error)));
            });

            document.getElementsByTagName('head')[0].appendChild(s);
        }
    });
}
